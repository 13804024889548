import { FieldArray, useFormik, FormikProvider } from "formik";
import React, { useEffect, useState, useCallback, useRef } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import {
  areaCodeListingSelector,
  FetchAreaCodeList,
} from "../areacode/slice.js";
import { useSelector } from "react-redux";
import { SchoolListReducer } from "../slice.js";
import moment from "moment";
import { addUser } from "../../api/user.js";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { FetchUserDetail, userDetailSelector, details } from "./UserSlice.js";
import { fetchMembershipList } from "../membership/MembershipSlice.js";
import { addUserSchema, editUserSchema } from "../../utils/schems.js";
import Loader from "./../../component/Loader";
import { FetchDisciplineList } from "./../discipline/DisciplineSlice";
import { config, htmlStringToPdf, monthOption } from "../../utils/index.js";
import Phone from "../../component/Phone/Phone.jsx";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import BackButton from "../../component/BackButton";
import Select from "react-select";
import { FetchConsentList } from "../consent/ConsentSlice.js";
import { isValidPhoneNumber } from "libphonenumber-js";
import { sendMembershipSticker } from "../../api/memberships.js";
import DeleteModal from "../../component/Modal/Delete.jsx";
import { v4 as uuidv4 } from "uuid";
import { removeStudent } from "../../api/student.js";
import S3 from "aws-s3";
const S3Client = new S3(config);
const styles = {
  control: (styles) => {
    return {
      ...styles,
      borderWidth: 0,

      "&:hover": {
        borderColor: "transparent",
        outline: "0",
        boxShadow: "none",
      },
    };
  },
};

function Adduser() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const areaList = useSelector(areaCodeListingSelector);
  const userDetail = useSelector(userDetailSelector);
  const schoolList = useSelector((state) => state.main.schools);
  const [areacode, setAreacode] = useState("");
  const [loading, setLoading] = useState(false);
  const disciplines = useSelector((state) => state.discipline.list);
  const consentList = useSelector((state) => state.consent.list);
  const [yearList, setYearList] = useState([]);
  const [studentYearList, setStudentYearList] = useState([]);
  const [membershipYearList, setMembershipYearList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [delSubmitting, setDelSubmitting] = useState(false);
  const [delId, setDelId] = useState("");
  const arrayHelpersRef = useRef(null);
  const [delIndex, setDelIndex] = useState("");
  const handleShow = useCallback(() => {
    setShow(!show);
  }, [show]);

  useEffect(() => {
    dispatch(FetchConsentList({ page: 1, limit: 100000 }));
  }, [dispatch]);

  const getDisciplines = useCallback(() => {
    dispatch(FetchDisciplineList({ page: 1, limit: 1000000 }));
  }, [dispatch]);

  useEffect(() => {
    getDisciplines();
  }, [getDisciplines]);

  useEffect(() => {
    if (userDetail) {
      const arr = userDetail?.student_list;
      arr.map(async (item, i) => {
        await getList(item?.areacode, i);
        await getMembershipListings(i, item?.student_type, item?.discipline);
      });
    }
  }, [userDetail]);

  useEffect(() => {
    dispatch(FetchAreaCodeList({}));
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(FetchUserDetail({ userId: id })).then(() => {
        setLoading(false);
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(details(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (userDetail) {
      userDetail?.student_list?.map(async (it, i) => {
        return await getMembershipListings(i, it?.student_type, it?.discipline);
      });
    }
  }, [userDetail]);

  // useEffect(() => {
  //   if (yearList?.length === 0) {
  //     let minYear = moment().subtract(4, "years").format("YYYY");
  //     let years = [];
  //     for (let i = 1920; i <= minYear; i++) {
  //       years.push(i?.toString());
  //     }
  //     years.sort((a, b) => b - a);
  //     setYearList(years);
  //   }
  // }, [yearList?.length]);
  useEffect(() => {
    if (!yearList || yearList.length === 0) {
      const minYear = moment().subtract(18, "years").year(); // Minimum year for 18 years old
      const years = Array.from({ length: minYear - 1920 + 1 }, (_, i) =>
        (minYear - i).toString()
      );
      setYearList(years);
    }
  }, [yearList]);

  useEffect(() => {
    if (!studentYearList || studentYearList.length === 0) {
      const minYear = moment().year();
      const years = Array.from({ length: minYear - 1920 + 1 }, (_, i) =>
        (minYear - i).toString()
      );
      setStudentYearList(years);
    }
  }, [studentYearList]);

  useEffect(() => {
    if (membershipYearList?.length === 0) {
      let years = [];
      let now = moment().add(10, "years").format("YYYY");
      let startFrom = moment().format("YYYY");
      for (let i = startFrom; i <= now; i++) {
        years.push(i);
      }
      years.sort((a, b) => b - a);
      setMembershipYearList(years);
    }
  }, [membershipYearList?.length]);

  const formik = useFormik({
    initialValues: {
      first_name: userDetail?.first_name || "",
      last_name: userDetail?.last_name || "",
      username: userDetail?.username || "",
      email: userDetail?.email || "",
      password: userDetail?.password || "",
      mobile:
        userDetail?.address_details?.mobile &&
        userDetail?.address_details?.countryCode?.charAt(0) === "+"
          ? `${userDetail?.address_details?.countryCode}${userDetail?.address_details?.mobile}`
          : userDetail?.address_details?.mobile &&
            userDetail?.address_details?.countryCode?.charAt(0) !== "+"
          ? `+${userDetail?.address_details?.countryCode}${userDetail?.address_details?.mobile}`
          : "",
      address: {
        property_number: userDetail?.address_details?.property_number || "",
        property_name: userDetail?.address_details?.property_name || "",
        street_number: userDetail?.address_details?.street_number || "",
        town: userDetail?.address_details?.town || "",
        country: userDetail?.address_details?.country || "",
        postal_code: userDetail?.address_details?.postal_code || "",

        dob: userDetail?.address_details?.dob || "",
        dob_year: userDetail?.address_details?.dob_year
          ? userDetail?.address_details?.dob_year
          : userDetail?.address_details?.dob !== "0000-00-00"
          ? moment(userDetail?.address_details?.dob).format("YYYY")
          : "",
        dob_month: userDetail?.address_details?.dob_month
          ? userDetail?.address_details?.dob_month
          : userDetail?.address_details?.dob !== "0000-00-00"
          ? moment(userDetail?.address_details?.dob).format("M")
          : "",
        dob_day: userDetail?.address_details?.dob_day
          ? userDetail?.address_details?.dob_day
          : userDetail?.address_details?.dob !== "0000-00-00"
          ? Number(moment(userDetail?.address_details?.dob).format("DD"))
          : "",
      },

      students: userDetail?.student_list?.map((it) => {
        return {
          first_name: it?.first_name || "",
          last_name: it?.last_name || "",
          // dob: it?.dob || "",
          dob_day:
            it?.dob && it?.dob !== "0000-00-00"
              ? Number(moment(it?.dob).format("DD"))
              : Number(it?.dob_day)
              ? Number(it?.dob_day)
              : "",
          dob_month:
            it?.dob && it?.dob !== "0000-00-00"
              ? Number(moment(it?.dob).format("M"))
              : Number(it?.dob_month)
              ? Number(it?.dob_month)
              : "",
          dob_year:
            it?.dob && it?.dob !== "0000-00-00"
              ? Number(moment(it?.dob).format("YYYY"))
              : Number(it?.dob_year)
              ? Number(moment(it?.dob_year).format("YYYY"))
              : "",
          student_type: it?.student_type || "",
          discipline: it?.discipline || "",
          emergency_mobile:
            it?.emergency_contactNo !== "null" &&
            it?.emergency_contactNo !== "undefined"
              ? `${it?.emergency_countryCode}${it?.emergency_contactNo}`
              : "",

          medical_note: it?.medical_note || "",
          schoolId: it?.schoolId || "",
          areacode: it?.areacode || "",
          membership_level:
            it?.trialAccountStatus === "3" && it?.membership_details
              ? {
                  child_price:
                    it?.membership_details?.membership_details?.child_price,
                  adult_price:
                    it?.membership_details?.membership_details?.adult_price,
                  id: it?.membership_details?.membership_details?.id,
                  name: it?.membership_details?.membership_details?.name,
                  no_of_days:
                    it?.membership_details?.membership_details?.no_of_days,
                  student_type:
                    it?.membership_details?.membership_details?.student_type,
                  isActive: it?.membership_details?.isActive,
                  period:
                    it?.membership_details?.membership_details?.time_period,
                }
              : "",
          studentId: it?.id || "",
          trialAccountStatus:
            it?.trialAccountStatus === "0" ? "" : it?.trialAccountStatus,
          membership_year:
            it?.trialAccountStatus === "3" && it?.membership_details
              ? Number(
                  moment(it?.membership_details?.valid_till).format("YYYY")
                )
              : "",
          membership_month:
            it?.trialAccountStatus === "3" && it?.membership_details
              ? Number(moment(it?.membership_details?.valid_till).format("MM"))
              : "",
          membership_day:
            it?.trialAccountStatus === "3" && it?.membership_details
              ? Number(moment(it?.membership_details?.valid_till).format("DD"))
              : "",
          gender: it?.gender || "",
        };
      }) || [
        {
          first_name: "",
          last_name: "",
          student_type: "",
          discipline: "",
          dob_day: "",
          dob_month: "",
          dob_year: "",
          emergency_mobile: "",
          medical_note: "",
          schoolId: "",
          areacode: "",
          size: "",
          venueList: [],
          membership_levels: [],
          membership_level: "",
          studentId: "",
          trialAccountStatus: "",
        },
      ],
      user_consents: userDetail?.consent || [],

      toggle: "false",
    },
    enableReinitialize: true,
    validationSchema: id ? editUserSchema : addUserSchema,
    onSubmit: (values) => {
      const isValid = values?.mobile
        ? isValidPhoneNumber(values?.mobile)
        : false;
      let payload = {};
      const countryCode = values?.mobile
        ? formatPhoneNumberIntl(values?.mobile).split(" ")[0]
        : "";
      const studentD = userDetail?.student_list;
      if (id) {
        payload = {
          first_name: values.first_name || "",
          last_name: values.last_name || "",
          username: values.username || "",
          email: values.email || "",
          password: values.password || "",
          userId: id ? id : "",
          address: {
            ...values.address,
            mobile: values?.mobile
              ? values?.mobile.replace(countryCode, "")
              : "",
            countryCode: values?.mobile ? countryCode : "",
          },
          students: values.students?.map((it, i) => {
            const cc = it?.emergency_mobile
              ? formatPhoneNumberIntl(it?.emergency_mobile).split(" ")[0]
              : "";

            const st_type = handleStudentType(
              it?.dob_day,
              it?.dob_month,
              it?.dob_year,
              i
            );
            const studentIndex = studentD?.findIndex(
              (st) => it?.studentId?.toString() === st?.id?.toString()
            );
            console.log(studentIndex, "Student index");
            const isNewStudent =
              studentD?.findIndex(
                (st) => it?.studentId?.toString() === st?.id?.toString()
              ) === -1;
            console.log(isNewStudent, "is new Student index");

            const membeValidDate = moment(
              `${it?.membership_year}-${
                it?.membership_month < 10
                  ? `0${it?.membership_month}`
                  : it?.membership_month
              }-${
                it?.membership_day < 10
                  ? `0${it?.membership_day}`
                  : it?.membership_day
              }`,
              "YYYY-MM-DD"
            ).format("YYYY-MM-DD");

            return {
              first_name: it?.first_name || "",
              last_name: it?.last_name || "",
              student_type: st_type || "",
              discipline: it?.discipline || "",
              emergency_contactNo: it?.emergency_mobile
                ? it?.emergency_mobile?.replace(cc, "")
                : "",
              emergency_countryCode: it?.emergency_mobile ? cc : "",
              medical_note: it?.medical_note || "",
              schoolId: it?.schoolId || "",
              areacode: it?.areacode || "",
              size: it?.size || "",
              membership_level:
                it?.trialAccountStatus === "1"
                  ? ""
                  : isNewStudent
                  ? it?.membership_level?.id
                  : it?.trialAccountStatus === "3" &&
                    it?.membership_level &&
                    !studentD?.[studentIndex]?.membership_details?.isActive
                  ? it?.membership_level?.id
                  : it?.trialAccountStatus === "3" &&
                    studentD?.[studentIndex]?.membership_details?.isActive &&
                    it.membership_level?.id?.toString() !==
                      studentD?.[
                        i
                      ]?.membership_details?.membership_details?.id?.toString()
                  ? it?.membership_level?.id
                  : "",
              // must be added only when the membership is already purchased and want to change the expiry date of that membership
              expiry_date: isNewStudent
                ? moment(
                    `${it?.membership_year}-${
                      it?.membership_month < 10
                        ? `0${it?.membership_month}`
                        : it?.membership_month
                    }-${
                      it?.membership_day < 10
                        ? `0${it?.membership_day}`
                        : it?.membership_day
                    }`,
                    "YYYY-MM-DD"
                  ).format("YYYY-MM-DD")
                : it.trialAccountStatus === "3" &&
                  it?.membership_year &&
                  !studentD?.[studentIndex]?.membership_details?.isActive
                ? moment(
                    `${it?.membership_year}-${
                      it?.membership_month < 10
                        ? `0${it?.membership_month}`
                        : it?.membership_month
                    }-${
                      it?.membership_day < 10
                        ? `0${it?.membership_day}`
                        : it?.membership_day
                    }`,
                    "YYYY-MM-DD"
                  ).format("YYYY-MM-DD")
                : it.trialAccountStatus === "3" &&
                  it?.membership_year &&
                  studentD?.[studentIndex]?.membership_details?.isActive &&
                  (studentD?.[studentIndex]?.membership_details?.valid_till !==
                    membeValidDate ||
                    it.membership_level?.id?.toString() !==
                      studentD?.[
                        i
                      ]?.membership_details?.membership_details?.id?.toString())
                ? moment(
                    `${it?.membership_year}-${
                      it?.membership_month < 10
                        ? `0${it?.membership_month}`
                        : it?.membership_month
                    }-${
                      it?.membership_day < 10
                        ? `0${it?.membership_day}`
                        : it?.membership_day
                    }`,
                    "YYYY-MM-DD"
                  ).format("YYYY-MM-DD")
                : "",
              student_membership_id: isNewStudent
                ? null
                : it.trialAccountStatus === "3" &&
                  it?.membership_level &&
                  studentD?.[studentIndex]?.membership_details?.valid_till !==
                    membeValidDate
                ? studentD?.[studentIndex]?.membership_details?.id
                : "",
              studentId: it?.studentId || null,
              trial_membership: it?.trialAccountStatus,
              dob: `${it?.dob_year}-${it?.dob_month}-${it?.dob_day}`,
              dob_year: it?.dob_year || "",
              dob_month: it?.dob_month || "",
              dob_day: it?.dob_day || "",
              gender: it?.gender || "",
            };
          }),
          user_consents: values.user_consents,
        };
      } else {
        payload = {
          first_name: values.first_name || "",
          last_name: values.last_name || "",
          username: values.username || "",
          email: values.email || "",
          password: values.password || "",
          userId: id ? id : "",
          address: {
            ...values.address,
            mobile: values?.mobile
              ? values?.mobile.replace(countryCode, "")
              : "",
            countryCode: values?.mobile ? countryCode : "",
          },
          students: values.students?.map((it, i) => {
            const cc = it?.emergency_mobile
              ? formatPhoneNumberIntl(it?.emergency_mobile).split(" ")[0]
              : "";
            const st_type = handleStudentType(
              it?.dob_day,
              it?.dob_month,
              it?.dob_year,
              i
            );
            const isNewStudent =
              studentD?.findIndex((st) => it?.studentId === st?.id) === -1;

            const studentIndex = studentD?.findIndex(
              (st) => it?.studentId === st?.id
            );
            const membeValidDate = moment(
              `${it?.membership_year}-${
                it?.membership_month < 10
                  ? `0${it?.membership_month}`
                  : it?.membership_month
              }-${
                it?.membership_day < 10
                  ? `0${it?.membership_day}`
                  : it?.membership_day
              }`,
              "YYYY-MM-DD"
            ).format("YYYY-MM-DD");
            return {
              first_name: it?.first_name || "",
              last_name: it?.last_name || "",
              student_type: st_type || "",
              discipline: it?.discipline || "",
              emergency_contactNo: it?.emergency_mobile
                ? it?.emergency_mobile?.replace(cc, "")
                : "",
              emergency_countryCode: it?.emergency_mobile ? cc : "",
              medical_note: it?.medical_note || "",
              schoolId: it?.schoolId || "",
              areacode: it?.areacode || "",
              size: it?.size || "",
              membership_level:
                it?.trialAccountStatus === "1"
                  ? ""
                  : isNewStudent
                  ? it?.membership_level?.id
                  : it?.trialAccountStatus === "3" &&
                    it?.membership_level &&
                    !studentD?.[studentIndex]?.membership_details?.isActive
                  ? it?.membership_level?.id
                  : it?.trialAccountStatus === "3" &&
                    studentD?.[studentIndex]?.membership_details?.isActive &&
                    it.membership_level?.id?.toString() !==
                      studentD?.[
                        i
                      ]?.membership_details?.membership_details?.id?.toString()
                  ? it?.membership_level?.id
                  : "",
              // no_of_days:
              //   it?.trialAccountStatus === "1"
              //     ? ""
              //     : it?.membership_level?.no_of_days
              //     ? it?.membership_level?.no_of_days
              //     : "",
              // price:
              //   it?.trialAccountStatus === "1"
              //     ? ""
              //     : it?.student_type?.toLowerCase() === "child"
              //     ? it?.membership_level?.child_price
              //     : it?.student_type?.toLowerCase() === "adult"
              //     ? it?.membership_level?.adult_price
              //     : "",
              studentId: it?.studentId || "",
              student_membership_id: isNewStudent
                ? null
                : it.trialAccountStatus === "3" &&
                  it?.membership_level &&
                  studentD?.[studentIndex]?.membership_details?.valid_till !==
                    membeValidDate
                ? studentD?.[studentIndex]?.membership_details?.id
                : "",
              dob: `${it?.dob_year}-${it?.dob_month}-${it?.dob_day}`,

              trial_membership: it?.trialAccountStatus,
              dob_year: it?.dob_year || "",
              dob_month: it?.dob_month || "",
              dob_day: it?.dob_day || "",
              gender: it?.gender || "",
            };
          }),
          user_consents: values.user_consents,
        };
      }
      const isConsentMarked =
        values.user_consents?.length === 0
          ? false
          : values.user_consents?.every(
              (it) =>
                it.value &&
                it.value !== "" &&
                it.value !== null &&
                it.value !== undefined
            );
      if (isConsentMarked) {
        if (isValid) {
          setSubmitting(true);

          addUser(payload).then((res) => {
            if (res?.code === 1) {
              const studentData = res?.data?.user?.student_list;
              const userData = res?.data?.user;
              const sendSticker = async () => {
                let filteredStudentToSendSticker = [];

                if (!id) {
                  filteredStudentToSendSticker = studentData?.filter((item) => {
                    return item?.trialAccountStatus === "3";
                  });
                  if (filteredStudentToSendSticker?.length === 0) {
                    return Promise.all([res]);
                  }
                  let response = Promise.all(
                    filteredStudentToSendSticker?.map(async (st) => {
                      let promiseData = await handleSendSticker(
                        st,

                        userData
                      );
                      return promiseData;
                    })
                  );
                  return response;
                } else {
                  filteredStudentToSendSticker = studentData
                    ?.map((item) => {
                      console.log(item, "Student data from api response");
                      console.log(studentD, "StudentD data");

                      let index =
                        studentD?.findIndex((st, i) => {
                          return (
                            item?.trialAccountStatus === "3" &&
                            item?.trialAccountStatus !== "1" &&
                            item?.membership_details &&
                            !st?.memberhsip_details
                          );
                        }) === -1;
                      return index ? item : null;
                    })
                    ?.filter((it) => it !== null);
                  console.log(filteredStudentToSendSticker, "Filtered student");
                  if (filteredStudentToSendSticker?.length === 0) {
                    return Promise.all([res]);
                  }

                  let response = Promise.all(
                    filteredStudentToSendSticker?.map(async (st) => {
                      let promiseData = await handleSendSticker(
                        st,

                        userData
                      );
                      return promiseData;
                    })
                  );
                  return response;
                }
              };

              sendSticker().then(async (res) => {
                setSubmitting(false);
                let isTruthy = res?.every((it) => it?.code === 1);
                if (isTruthy) {
                  toast.success(res?.[0].message);
                  navigate("/users", { replace: true });
                } else {
                  toast.error(res?.message);
                }
              });
            } else {
              toast.error(res?.message);
            }
          });
        } else {
          formik.setFieldError("mobile", "Kindly enter a valid phone number.");
          toast.error("Kindly enter a valid phone number.");
        }
      } else {
        toast.error("Kindly mark all the consent points.");
      }
    },
  });

  const handleSendSticker = async (studentD, userD) => {
    const studentData = studentD;
    const userData = userD;
    let st = studentD;
    const sendHtmlAsString = async (nameD = "") => {
      let htmlPart = `<!DOCTYPE html>
                <html lang="en">

                <head>
                    <meta charset="utf-8">
                    <meta http-equiv="Content-Type" content="text/html;charset=UTF-8">
                    <meta http-equiv="X-UA-Compatible" content="IE=edge">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">

                    <title>Membership Details</title>

                </head>

                <body style="width: 100 mm;height: 56 mm;">
                    <div class="mw-body"
                        style="width: 100%;height: 100%;
                    font-family: Roboto Slab,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji">
                        <div>
                            <table style="width: 100%;height: 100%;border-collapse: separate;
                            border-spacing: 0 0em;text-transform: uppercase; font-size: 12px; font-weight: 700;margin: 1mm; padding: 5px;">
                                <tr>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                    border-right: 0;"><span>name</span></td>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span style="color: #ff0000;"> ${
                                      st?.first_name
                                    } ${st?.last_name}</span></td>

                                </tr>
                                <tr>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        border-right: 0;"><span>address</span></td>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span
                                            style="color: #ff0000;"> ${
                                              userData?.address_details
                                                ?.property_name
                                            }, ${
        userData?.address_details?.property_number
          ? `${userData?.address_details?.property_number}, `
          : ""
      } ${userData?.address_details?.street_number}</span></td>

                                </tr>
                                <tr>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        border-right: 0;"><span>town</span></td>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        "><span style="color: #ff0000;"> ${
                                          userData?.address_details?.town
                                        }</span></td>
                                </tr>
                                <tr>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        border-right: 0;"><span>postcode</span></td>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        "><span style="color: #ff0000;"> ${
                                          userData?.address_details?.postal_code
                                        }</span></td>
                                </tr>
                                <tr>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        border-right: 0;"><span>dob</span></td>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        "><span style="color: #ff0000;"> ${moment(
                                          st?.dob
                                        ).format("DD MMM YYYY")}</span></td>
                                </tr>
                                <tr>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        border-right: 0;"><span>school</span></td>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        "><span style="color: #ff0000;"> ${
                                          st?.school_details?.name
                                        }</span></td>
                                </tr>
                                  <tr>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        border-right: 0;"><span>discipline</span></td>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        "><span style="color: #ff0000;"> ${
                                          st?.discipline_details?.name
                                        }</span></td>
                                </tr>
                                <tr>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        border-right: 0;"><span>first licence</span></td>
                                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                        "><span style="color: #ff0000;"> ${moment(
                                          st?.membership_details?.start_date
                                        ).format("DD MMM YYYY")}</span></td>
                                </tr>
                                <tr>
                                    <td style="border-collapse: collapse;padding: 1mm;
                                        border-right: 0;"><span>date</span></td>
                                    <td style="border-collapse: collapse;padding: 1mm;
                                        "><span style="color: #ff0000;"> ${moment(
                                          st?.membership_details?.valid_till
                                        ).format("DD MMM YYYY")}</span></td>
                                </tr>
                            </table>
                        </div>
                </body>

                </html>`;

      const pdfOutput = await htmlStringToPdf(htmlPart);

      return pdfOutput;
    };

    let htmlStringResponse = await sendHtmlAsString()
      .then((ob) => {
        if (ob) {
          return ob;
        }
      })
      .catch((err) => err);

    const newFileName = uuidv4();
    let ss3 = await S3Client.uploadFile(htmlStringResponse, newFileName)
      .then(async (data) => {
        return data.location;
      })
      .catch((err) => err);
    const stickerPayload = {
      user_membership_id: studentData?.membership_details?.id,
      file_url: ss3,
      userId: studentData?.userId,
    };
    let stickerSender = await sendMembershipSticker(stickerPayload)
      .then((res) => {
        return res;
      })
      .catch((err) => err);
    return stickerSender;
  };

  const getList = (area, index) => {
    dispatch(
      SchoolListReducer({
        page: 1,
        limit: 10000,
        areacode: area,
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        const data = res?.payload?.data?.school_list;
        formik.setFieldValue(`students.${index}.venueList`, data);
      }
    });
  };

  const getMembershipListings = (index, type, discipline) => {
    dispatch(
      fetchMembershipList({
        page: 1,
        limit: 10000,
        student_type: type,
        discipline: discipline,
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        const data = res?.payload?.data?.membership_list?.map((it) => {
          return {
            child_price: it?.child_price,
            adult_price: it?.adult_price,
            id: it?.id,
            name: it?.name,
            no_of_days: it?.no_of_days,
            student_type: it?.student_type,
            isActive: it?.isActive,
            time_period: it?.time_period,
          };
        });
        formik.setFieldValue(`students.${index}.membership_levels`, data);
      }
    });
  };

  const handleStudentType = (day, month, year, index) => {
    let days = day ? day : formik.values.students?.[index]?.dob_day;
    let months = month ? month : formik.values.students?.[index]?.dob_month;
    let years = year ? year : formik.values.students?.[index]?.dob_year;
    if (day && month && year) {
      const date1 = new Date();
      const date2 = new Date(`${months}/${days}/${years}`);
      let diff = (date1.getTime() - date2.getTime()) / 1000;
      diff /= 60 * 60 * 24;
      diff = Math.abs(Math.round(diff / 365.25));

      return diff >= 4 && diff <= 16 ? "child" : "adult";
    }
    // if (day && month && year) {
    //   const bday = moment(`${year}-${month}-${day}`);
    //   const today = moment(new Date());
    //   const diff = today.diff(bday, "years");

    //   if (diff >= 4 && diff <= 16) {
    //     formik.setFieldValue(`students.${index}.student_type`, "child");
    //   } else if (diff >= 16) {
    //     formik.setFieldValue(`students.${index}.student_type`, "adult");
    //   } else {
    //     console.log("Else part running");
    //     formik.setFieldValue(`students.${index}.student_type`, "");
    //   }
    // }
  };

  const isValid = (num) => {
    if (num && !isValidPhoneNumber(num)) {
      formik.setFieldError("mobile", "Kindly enter a valid number.");

      return false;
    } else {
      formik.setFieldError("mobile", "");
    }
    return true;
  };

  const isStudentNumberValid = (num, i) => {
    if (num && !isValidPhoneNumber(num)) {
      formik.setFieldError(
        `students.${i}.emergency_mobile`,
        "Kindly enter a valid number."
      );

      return false;
    } else {
      formik.setFieldError(`students.${i}.emergency_mobile`, "");
    }
    return true;
  };

  const confirmDelete = () => {
    if (delId) {
      setDelSubmitting(true);
      removeStudent({ studentId: delId }).then((res) => {
        setDelSubmitting(false);
        if (res?.code === 1) {
          // toast.success(res?.message);
          arrayHelpersRef.current.remove(delIndex);
          dispatch(FetchUserDetail({ userId: id }));
          setDelId("");
          setDelIndex("");
        } else {
          toast.error(res?.message);
        }
      });
    } else {
      toast.error("No studnet id found");
    }
  };

  if (loading) {
    return (
      <article className="col-lg-9 articleClass">
        <Loader />
      </article>
    );
  } else {
    return (
      <article className="col-lg-9 articleClass">
        <div className="article-body store-bd-gy">
          <div className="row align-items-center col-rever">
            <BackButton />

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>{id ? "Edit User" : "Add New User"}</h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12">
              <FormikProvider value={formik}>
                <form className="form-style" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 mb-4">
                      <div className="form-group">
                        <h3>Account Holder Details</h3>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">First Name</label>
                        {formik.errors.first_name &&
                          formik.touched.first_name && (
                            <span className="text-red ms-1">
                              {formik.errors.first_name}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="First Name"
                            name="first_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.first_name}
                          />
                          <img
                            src="assets/images/First-Name.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Last Name</label>
                        {formik.errors.last_name &&
                          formik.touched.last_name && (
                            <span className="text-red ms-1">
                              {formik.errors.last_name}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Last Name"
                            name="last_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.last_name}
                          />
                          <img
                            src="assets/images/First-Name.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">User Name</label>
                        {formik.errors.username && formik.touched.username && (
                          <span className="text-red ms-1">
                            {formik.errors.username}
                          </span>
                        )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Username"
                            name="username"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.username}
                          />
                          <img
                            src="assets/images/First-Name.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Email</label>
                        {formik.errors.email && formik.touched.email && (
                          <span className="text-red ms-1">
                            {formik.errors.email}
                          </span>
                        )}
                        <div className="input-container">
                          <input
                            type="email"
                            className="form-control pl-5"
                            placeholder="Email ID"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          <img
                            src="assets/images/Email-Address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <label className="fw-bold mb-3">
                        Date of Birth
                      </label>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label className="fw-bold">Year</label>

                        {/* {formik.errors?.address?.dob_year &&
                          formik.touched?.address?.dob_year && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.dob_year}
                            </span>
                          )} */}
                        <div className="input-container">
                          <select
                            onChange={(e) => {
                              formik.setFieldValue(
                                "address.dob_year",
                                e.target.value
                              );
                            }}
                            value={formik.values.address.dob_year || ""}
                            className="form-control ps-4"
                          >
                            <option value="">Select Year</option>
                            {/* <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                            <option value="2016">2016</option>
                            <option value="2015">2015</option>
                            <option value="2014">2014</option>
                            <option value="2013">2013</option>
                            <option value="2012">2012</option>
                            <option value="2011">2011</option>
                            <option value="2010">2010</option>
                            <option value="2009">2009</option>
                            <option value="2008">2008</option>
                            <option value="2007">2007</option>
                            <option value="2006">2006</option>
                            <option value="2005">2005</option>
                            <option value="2004">2004</option>
                            <option value="2003">2003</option>
                            <option value="2002">2002</option>
                            <option value="2001">2001</option>
                            <option value="2000">2000</option>
                            <option value="1999">1999</option>
                            <option value="1998">1998</option>
                            <option value="1997">1997</option>
                            <option value="1996">1996</option>
                            <option value="1995">1995</option>
                            <option value="1994">1994</option>
                            <option value="1993">1993</option>
                            <option value="1992">1992</option>
                            <option value="1991">1991</option>
                            <option value="1990">1990</option>
                            <option value="1989">1989</option>
                            <option value="1987">1987</option>
                            <option value="1986">1986</option>
                            <option value="1985">1985</option>
                            <option value="1984">1984</option>
                            <option value="1983">1983</option>
                            <option value="1982">1982</option>
                            <option value="1981">1981</option>
                            <option value="1980">1980</option> */}
                            {yearList?.map((item) => {
                              return (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>

                          <img
                            src="assets/images/choose-date.png"
                            className="field-icon"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label className="fw-bold">Month</label>

                        {formik.errors?.address?.dob_month &&
                          formik.touched?.address?.dob_month && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.dob_month}
                            </span>
                          )}
                        <div className="input-container">
                          <select
                            onChange={(e) => {
                              formik.setFieldValue(
                                "address.dob_month",
                                e.target.value
                              );
                            }}
                            value={formik.values.address.dob_month || ""}
                            className="form-control ps-4"
                          >
                            <option value="">Select Month</option>
                            {monthOption?.map((item) => (
                              <option value={item?.id} key={item?.id}>
                                {item?.value}
                              </option>
                            ))}
                          </select>

                          <img
                            src="assets/images/choose-date.png"
                            className="field-icon"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label className="fw-bold">Day</label>

                        {formik.errors?.address?.dob_day &&
                          formik.touched?.address?.dob_day && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.dob_day}
                            </span>
                          )}
                        <div className="input-container">
                          <select
                            onChange={(e) => {
                              formik.setFieldValue(
                                "address.dob_day",
                                e.target.value.toString()
                              );
                            }}
                            value={formik.values.address.dob_day || ""}
                            className="form-control ps-4"
                          >
                            <option value={""}>Select Day</option>
                            {[
                              ...Array(
                                formik.values.address.dob_day === "2" ? 28 : 31
                              ).keys(),
                            ].map((item) => {
                              return (
                                <option value={item + 1} key={item + 1}>
                                  {item + 1}
                                </option>
                              );
                            })}
                          </select>

                          <img
                            src="assets/images/choose-date.png"
                            className="field-icon"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      {formik.errors?.address?.dob_year &&
                        formik.touched?.address?.dob_year && (
                          <span className="text-red ms-1">
                            {formik.errors?.address?.dob_year}
                          </span>
                        )}
                    </div>
                    {/* {id && formik.errors?.address ? (
                      <div className="text-red">{formik.errors.address}</div>
                    ) : (
                      <div>
                        {formik.errors?.address &&
                          formik.errors.address.is18orOlder && (
                            <div className="text-red">
                              {formik.errors.address.is18orOlder}
                            </div>
                          )}
                      </div>
                    )} */}

                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Password</label>
                        {formik.errors.password && formik.touched.password && (
                          <span className="text-red ms-1">
                            {formik.errors.password}
                          </span>
                        )}
                        <div className="input-container">
                          <input
                            type={formik.values.toggle ? "password" : "text"}
                            className="form-control pl-5"
                            placeholder="Set Password"
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          <img
                            src="assets/images/password.png"
                            className="input-img"
                            alt=""
                          />
                          <span
                            className={
                              formik.values.toggle
                                ? "pass-view field-icon "
                                : "pass-hide field-icon "
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              formik.setFormikState((prevState) => {
                                return {
                                  ...prevState,
                                  values: {
                                    ...prevState.values,
                                    toggle: !prevState.values.toggle,
                                  },
                                };
                              });
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 text-center mb-4">
                      <hr />
                    </div>

                    <div className="col-sm-12 col-md-12 mb-4">
                      <div className="form-group">
                        <h3>Address Details</h3>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Property Number</label>
                        {formik.errors?.address?.property_number &&
                          formik.touched?.address?.property_number && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.property_number}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="number"
                            className="form-control pl-5"
                            placeholder="Property Number"
                            name="address.property_number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address.property_number}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Property Name</label>
                        {formik.errors?.address?.property_name &&
                          formik.touched?.address?.property_name && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.property_name}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Property Name"
                            name="address.property_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address.property_name}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Street Name</label>
                        {formik.errors?.address?.street_number &&
                          formik.touched?.address?.street_number && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.street_number}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Street Name"
                            name="address.street_number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address.street_number}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Town</label>
                        {formik.errors?.address?.town &&
                          formik.touched?.address?.town && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.town}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Town"
                            name="address.town"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address.town}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Country</label>
                        {formik.errors?.address?.country &&
                          formik.touched?.address?.country && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.country}
                            </span>
                          )}
                        <div className="input-container">
                          <select
                            className="form-control pl-5"
                            placeholder="Country"
                            name="address.country"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address.country}
                          >
                            <option value="">Select Country</option>
                            <option value="UK">U.K</option>
                          </select>

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Postal Code</label>
                        {formik.errors?.address?.postal_code &&
                          formik.touched?.address?.postal_code && (
                            <span className="text-red ms-1">
                              {formik.errors?.address?.postal_code}
                            </span>
                          )}
                        <div className="input-container">
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Postal Code"
                            name="address.postal_code"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address.postal_code}
                          />

                          <img
                            src="assets/images/address.png"
                            className="input-img"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-6 mb-4">
                      <div className="form-group">
                        <label className="fw-bold">Phone Number</label>
                        {formik.errors?.mobile && (
                          <span className="text-red ms-1">
                            {formik.errors?.mobile}
                          </span>
                        )}
                        <div className="input-container">
                          <Phone
                            onChange={(val) => {
                              formik.setFieldValue(`mobile`, val);
                            }}
                            value={formik.values.mobile || undefined}
                            name={`mobile`}
                            className="form-control signupClass pl-3"
                            isValid={() => {
                              isValid(formik.values.mobile);
                            }}
                          />
                          {/* <img
                            src="assets/images/mobile.png"
                            className="input-img"
                            alt=""
                          /> */}
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-sm-12 col-md-12 mb-4 bg-white p-3">
                      <label className="fw-bold">Consents</label>
                      <FieldArray
                        name="user_consents"
                        render={() => (
                          <>
                            {consentList?.length > 0 ? (
                              consentList?.map((cons, index) => {
                                return (
                                  <div className=" mt-3">
                                    <label>{cons?.label}</label>

                                    <div className="termCustomRadio">
                                      <p>
                                        {cons?.values?.map((val, ind) => {
                                          return (
                                            <span className="me-3">
                                              <input
                                                type={cons?.input_field_type}
                                                id={`test${index}${ind}`}
                                                name={`user_consents.${index}.value`}
                                                checked={
                                                  val?.value?.toLowerCase() ===
                                                  formik.values.user_consents?.[
                                                    index
                                                  ]?.value?.toLowerCase()
                                                }
                                                onChange={() => {
                                                  let payload = {
                                                    consent: cons?.id,
                                                    value: val?.value,
                                                    note: "",
                                                  };

                                                  formik.setFieldValue(
                                                    `user_consents.${index}`,
                                                    payload
                                                  );
                                                }}
                                              />
                                              <label
                                                htmlFor={`test${index}${ind}`}
                                              >
                                                {val?.value}
                                              </label>
                                            </span>
                                          );
                                        })}
                                      </p>
                                    </div>

                                    {formik.values.user_consents?.[index]
                                      ?.consent === 6 &&
                                      formik.values.user_consents?.[
                                        index
                                      ]?.value?.toLowerCase() === "yes" && (
                                        <>
                                          {" "}
                                          <div class="input-container term-checkbox mt-4 ms-3 d-lg-flex align-items-center">
                                            <span class="position-relative align-items-center">
                                              <input
                                                type="text"
                                                class="textBox form-control ms-2 medication"
                                                placeholder="Name of medication and why it is required?"
                                                name={`user_consents.${index}.note`}
                                                value={
                                                  formik.values.user_consents?.[
                                                    index
                                                  ]?.note
                                                }
                                                onChange={(e) => {
                                                  formik.setFieldValue(
                                                    `user_consents.${index}.note`,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </span>
                                          </div>
                                          {formik.values.user_consents?.[index]
                                            ?.note?.length < 1 && (
                                            <span className="text-danger">
                                              Required
                                            </span>
                                          )}
                                        </>
                                      )}
                                  </div>
                                );
                              })
                            ) : (
                              <div className=" mt-3">No Consent Added.</div>
                            )}
                          </>
                        )}
                      />
                      {formik.errors?.user_consents && (
                        <span className="text-danger">
                          {formik.errors?.user_consents}
                        </span>
                      )}
                    </div>

                    <div className="col-sm-12 col-md-12 text-center mb-4">
                      <hr />
                    </div>

                    <div className="col-sm-12 col-md-12 mb-4">
                      <div className="form-group">
                        <h3>Student Details</h3>
                      </div>
                    </div>

                    <FieldArray
                      name="students"
                      render={(arrayHelpers) => {
                        arrayHelpersRef.current = arrayHelpers;
                        return (
                          <>
                            {formik.values.students?.map((item, i) => {
                              const student_type = handleStudentType(
                                item?.dob_day,
                                item?.dob_month,
                                item?.dob_year,
                                i
                              );

                              let membershipMonth =
                                item?.membership_month < 10
                                  ? `0${item?.membership_month}`
                                  : item?.membership_month;
                              let membershipDay =
                                item?.membership_day < 10
                                  ? `0${item?.membership_day}`
                                  : item?.membership_day;

                              let membershipExpiry = moment(
                                `${item?.membership_year}-${membershipMonth}-${membershipDay}`,
                                "YYYY-MM-DD"
                              ).format("DD MMM YYYY");

                              return (
                                <div className="row position-relative" key={i}>
                                  <div className="col-sm-12 col-md-6 mb-4">
                                    <div className="form-group">
                                      <label className="fw-bold">
                                        Student First Name
                                      </label>
                                      {formik.errors?.students?.[i]
                                        ?.first_name &&
                                        formik.touched?.students?.[i]
                                          ?.first_name && (
                                          <span className="text-red ms-1">
                                            {
                                              formik.errors?.students?.[i]
                                                ?.first_name
                                            }
                                          </span>
                                        )}
                                      <div className="input-container">
                                        <input
                                          type="text"
                                          className="form-control pl-5"
                                          placeholder="Student First Name"
                                          name={`students.${i}.first_name`}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={item?.first_name}
                                        />
                                        <img
                                          src="assets/images/First-Name.png"
                                          className="input-img"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-6 mb-4">
                                    <div className="form-group">
                                      <label className="fw-bold">
                                        Student Last Name
                                      </label>
                                      {formik.errors?.students?.[i]
                                        ?.last_name &&
                                        formik.touched?.students?.[i]
                                          ?.last_name && (
                                          <span className="text-red ms-1">
                                            {
                                              formik.errors?.students?.[i]
                                                ?.last_name
                                            }
                                          </span>
                                        )}
                                      <div className="input-container">
                                        <input
                                          type="text"
                                          className="form-control pl-5"
                                          placeholder="Student Last Name"
                                          name={`students.${i}.last_name`}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={
                                            formik.values.students?.[i]
                                              ?.last_name
                                          }
                                        />
                                        <img
                                          src="assets/images/First-Name.png"
                                          className="input-img"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group col-sm-12 col-md-12 mb-4">
                                    <div className="">
                                      <label className="fw-bold">Gender</label>

                                      <div className="termCustomRadio">
                                        <p>
                                          <span className="me-3">
                                            <input
                                              type="radio"
                                              id={`male${i}`}
                                              name={`students.${i}.gender`}
                                              value="male"
                                              checked={item?.gender === "male"}
                                              onChange={formik.handleChange}
                                            />
                                            <label htmlFor={`male${i}`}>
                                              Male
                                            </label>
                                          </span>
                                          <span className="me-3">
                                            <input
                                              type="radio"
                                              id={`female${i}`}
                                              name={`students.${i}.gender`}
                                              value="female"
                                              checked={
                                                item?.gender === "female"
                                              }
                                              onChange={formik.handleChange}
                                            />
                                            <label htmlFor={`female${i}`}>
                                              Female
                                            </label>
                                          </span>
                                          <span className="me-3">
                                            <input
                                              type="radio"
                                              id={`other${i}`}
                                              name={`students.${i}.gender`}
                                              value="other"
                                              checked={item?.gender === "other"}
                                              onChange={formik.handleChange}
                                            />
                                            <label htmlFor={`other${i}`}>
                                              Other
                                            </label>
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    {formik.errors?.students?.[i]?.gender &&
                                      formik.touched?.students?.[i]?.gender && (
                                        <span className="text-red ms-1">
                                          {formik.errors?.students?.[i]?.gender}
                                        </span>
                                      )}
                                  </div>
                                  <div className="col-sm-12 mb-3">
                                    <label className="fw-bold">
                                      Student's Date Of Birth
                                    </label>
                                    {formik?.errors?.students?.[i]
                                      ?.student_type ? (
                                      <span className="text-red ms-1">
                                        {
                                          formik.errors?.students?.[i]
                                            ?.student_type
                                        }
                                      </span>
                                    ) : null}
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                    <div className="form-group">
                                      <label className="fw-bold">Year</label>

                                      {formik.errors?.students?.[i]?.dob_year &&
                                        formik.touched?.students?.[i]
                                          ?.dob_year && (
                                          <span className="text-red ms-1">
                                            {
                                              formik.errors?.students?.[i]
                                                ?.dob_year
                                            }
                                          </span>
                                        )}
                                      <div className="input-container">
                                        <select
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              `students.${i}.dob_year`,
                                              e.target.value
                                            );
                                            const st_type = handleStudentType(
                                              item.dob_day,
                                              item.dob_month,
                                              e.target.value,
                                              i
                                            );
                                            const discipline =
                                              formik.values?.students?.[i]
                                                ?.discipline;

                                            if (st_type && discipline)
                                              getMembershipListings(
                                                i,
                                                st_type,
                                                discipline
                                              );
                                          }}
                                          onBlur={formik.handleBlur}
                                          name={`students.${i}.dob_year`}
                                          value={item?.dob_year || ""}
                                          className="form-control ps-4"
                                        >
                                          <option value="">Select Year</option>

                                          {studentYearList?.map((item) => {
                                            return (
                                              <option value={item} key={item}>
                                                {item}
                                              </option>
                                            );
                                          })}
                                        </select>

                                        <img
                                          src="assets/images/choose-date.png"
                                          className="field-icon"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                    <div className="form-group">
                                      <label className="fw-bold">Month</label>

                                      {formik.errors?.students?.[i]
                                        ?.dob_month &&
                                        formik.touched?.students?.[i]
                                          ?.dob_month && (
                                          <span className="text-red ms-1">
                                            {
                                              formik.errors?.students?.[i]
                                                ?.dob_month
                                            }
                                          </span>
                                        )}
                                      <div className="input-container">
                                        <select
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              `students.${i}.dob_month`,
                                              e.target.value
                                            );
                                          }}
                                          onBlur={formik.handleBlur}
                                          name={`students.${i}.dob_month`}
                                          value={item?.dob_month || ""}
                                          className="form-control ps-4"
                                        >
                                          <option value="">Select Month</option>
                                          {monthOption?.map((item) => (
                                            <option
                                              value={item?.id}
                                              key={item?.id}
                                            >
                                              {item?.value}
                                            </option>
                                          ))}
                                        </select>

                                        <img
                                          src="assets/images/choose-date.png"
                                          className="field-icon"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                    <div className="form-group">
                                      <label className="fw-bold">Day</label>

                                      {formik.errors?.students?.[i]?.dob_day &&
                                        formik.touched?.students?.[i]
                                          ?.dob_day && (
                                          <span className="text-red ms-1">
                                            {
                                              formik.errors?.students?.[i]
                                                ?.dob_day
                                            }
                                          </span>
                                        )}
                                      <div className="input-container">
                                        <select
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              `students.${i}.dob_day`,
                                              e.target.value
                                            );
                                            let val =
                                              formik.values.students?.[i]
                                                ?.membership_level;
                                            let membership_number_of_days =
                                              val?.no_of_days;
                                            let period = val?.time_period;
                                            let final_date = moment().add(
                                              membership_number_of_days,
                                              "days"
                                            );

                                            if (final_date) {
                                              formik.setFieldValue(
                                                `students.${i}.membership_year`,
                                                final_date?.format("YYYY")
                                              );
                                              formik.setFieldValue(
                                                `students.${i}.membership_month`,
                                                final_date?.format("M")
                                              );
                                              formik.setFieldValue(
                                                `students.${i}.membership_day`,
                                                Number(final_date?.format("DD"))
                                              );
                                            }
                                          }}
                                          onBlur={formik.handleBlur}
                                          name={`students.${i}.dob_day`}
                                          value={item?.dob_day || ""}
                                          className="form-control ps-4"
                                        >
                                          <option value={""}>Select Day</option>
                                          {[
                                            ...Array(
                                              item?.dob_day === "2" ? 28 : 31
                                            ).keys(),
                                          ].map((item) => {
                                            return (
                                              <option
                                                value={item + 1}
                                                key={item + 1}
                                              >
                                                {item + 1}
                                              </option>
                                            );
                                          })}
                                        </select>

                                        <img
                                          src="assets/images/choose-date.png"
                                          className="field-icon"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-12 col-lg-12 mb-4 d-none">
                                    <div className="form-group addUser">
                                      <label className="fw-bold">
                                        Student Type
                                      </label>
                                      {formik.errors.students?.[i]
                                        ?.student_type &&
                                        formik.touched.students?.[i]
                                          ?.student_type && (
                                          <span className="text-red ms-1">
                                            {
                                              formik.errors.students?.[i]
                                                ?.student_type
                                            }
                                          </span>
                                        )}
                                      <div className="checkboxs">
                                        <label>
                                          <input
                                            type="radio"
                                            value="child"
                                            checked={
                                              item?.student_type === "child"
                                            }
                                            name={`students.${i}.student_type`}
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                `students.${i}.student_type`,
                                                e.target.value
                                              );
                                              getMembershipListings(
                                                i,
                                                e.target.value,
                                                formik.values.students?.[i]
                                                  ?.discipline
                                              );
                                            }}
                                            onBlur={formik.handleBlur}
                                          />
                                          <div className="div-bg">
                                            <p>
                                              <span>child</span>
                                            </p>
                                          </div>
                                        </label>

                                        <label>
                                          <input
                                            type="radio"
                                            value="adult"
                                            name={`students.${i}.student_type`}
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                `students.${i}.student_type`,
                                                e.target.value
                                              );
                                              getMembershipListings(
                                                i,
                                                e.target.value,
                                                formik.values.students?.[i]
                                                  ?.discipline
                                              );
                                            }}
                                            checked={
                                              item?.student_type === "adult"
                                            }
                                            onBlur={formik.handleBlur}
                                          />
                                          <div className="div-bg">
                                            <p>
                                              <span>adult</span>
                                            </p>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-12 mb-4">
                                    <div className="form-group addUser">
                                      <label className="fw-bold">
                                        {/* <img src="assets/images/discipline.png" alt="" />{" "} */}
                                        Discipline
                                      </label>
                                      {formik.errors.students?.[i]
                                        ?.discipline &&
                                        formik.touched.students?.[i]
                                          ?.discipline && (
                                          <span className="text-red ms-1">
                                            {
                                              formik.errors.students?.[i]
                                                ?.discipline
                                            }
                                          </span>
                                        )}
                                      <div className="checkboxs">
                                        {disciplines
                                          ?.filter(
                                            (it) =>
                                              it?.name?.toLowerCase() !==
                                              "all disciplines"
                                          )
                                          ?.map((disc, ind) => {
                                            return (
                                              <label key={ind}>
                                                <input
                                                  type="radio"
                                                  value={disc?.id}
                                                  name={`students.${i}.discipline`}
                                                  checked={
                                                    item?.discipline?.toString() ===
                                                    disc?.id?.toString()
                                                  }
                                                  onChange={(e) => {
                                                    formik.setFieldValue(
                                                      `students.${i}.discipline`,
                                                      e.target.value
                                                    );
                                                    formik.setFieldValue(
                                                      `students.${i}.membership_level`,
                                                      ""
                                                    );

                                                    const st_type =
                                                      handleStudentType(
                                                        item.dob_day,
                                                        item.dob_month,
                                                        item?.dob_year,
                                                        i
                                                      );
                                                    getMembershipListings(
                                                      i,

                                                      st_type,
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                                <div className="div-bg">
                                                  <p>
                                                    <span>{disc?.name}</span>
                                                  </p>
                                                </div>
                                              </label>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                  {
                                    <div className="col-sm-12 mb-4">
                                      <div className="form-group addUser">
                                        <label className="fw-bold mb-2">
                                          Membership Type
                                        </label>
                                        {formik.errors?.students?.[i]
                                          ?.trialAccountStatus &&
                                          formik.touched?.students?.[i]
                                            ?.trialAccountStatus && (
                                            <span className="text-red ms-1">
                                              {
                                                formik.errors?.students?.[i]
                                                  ?.trialAccountStatus
                                              }
                                            </span>
                                          )}

                                        <div className="checkboxs">
                                          <label>
                                            <input
                                              type="radio"
                                              checked={
                                                item?.trialAccountStatus === "1"
                                              }
                                              name={`students.${i}.trialAccountStatus`}
                                              onChange={() => {
                                                formik.setFieldValue(
                                                  `students.${i}.trialAccountStatus`,
                                                  "1"
                                                );
                                                formik.setFieldValue(
                                                  `students.${i}.membership_level`,
                                                  ""
                                                );
                                              }}
                                            />
                                            <div className="div-bg">
                                              <p>
                                                <span>
                                                  Pay For Trail Lessons
                                                </span>
                                              </p>
                                            </div>
                                          </label>

                                          <label>
                                            <input
                                              type="radio"
                                              checked={
                                                item?.trialAccountStatus === "3"
                                              }
                                              name={`students.${i}.trialAccountStatus`}
                                              onChange={() => {
                                                formik.setFieldValue(
                                                  `students.${i}.trialAccountStatus`,
                                                  "3"
                                                );

                                                const st_type =
                                                  handleStudentType(
                                                    item?.dob_day,
                                                    item?.dob_month,
                                                    item?.dob_year,
                                                    i
                                                  );
                                                const discipline =
                                                  formik.values?.students?.[i]
                                                    ?.discipline;

                                                if (!st_type) {
                                                  toast.error(
                                                    "Kindly add student date of birth."
                                                  );
                                                }

                                                if (st_type && discipline)
                                                  getMembershipListings(
                                                    i,
                                                    st_type,
                                                    discipline
                                                  );
                                              }}
                                            />
                                            <div className="div-bg">
                                              <p>
                                                <span>Full Membership</span>
                                              </p>
                                            </div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  {item?.trialAccountStatus === "3" &&
                                    student_type &&
                                    item?.discipline && (
                                      <div className="col-sm-6 col-md-6 mb-4">
                                        <div className="form-group">
                                          <label className="fw-bold">
                                            Membership
                                          </label>
                                          {formik.errors?.students?.[i]
                                            ?.membership_level &&
                                            formik.touched?.students?.[i]
                                              ?.membership_level && (
                                              <span className="text-red ms-1">
                                                {
                                                  formik.errors?.students?.[i]
                                                    ?.membership_level
                                                }
                                              </span>
                                            )}
                                          <div className="input-container">
                                            {/* <select
                                            className="form-control pl-5"
                                            placeholder="Select Membership"
                                            name={`students.${i}.membership_level`}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={
                                              formik.values.students?.[i]
                                                ?.membership_level
                                            }
                                          >
                                            <option value="">
                                              Select Membership
                                            </option>
                                            {item?.membership_levels?.map(
                                              (item, i) => {
                                                return (
                                                  <option
                                                    value={item?.id}
                                                    key={i}
                                                  >
                                                    {item?.name}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select> */}
                                            <Select
                                              name={`students.${i}.membership_level`}
                                              className="form-control pl-5"
                                              options={item?.membership_levels}
                                              getOptionLabel={(option) =>
                                                option?.name
                                              }
                                              placeholder="Select membership"
                                              getOptionValue={(option) =>
                                                option
                                              }
                                              styles={styles}
                                              value={
                                                formik.values.students?.[i]
                                                  ?.membership_level
                                              }
                                              onChange={(val) => {
                                                formik.setFieldValue(
                                                  `students.${i}.membership_level`,
                                                  val
                                                );

                                                let membership_number_of_days =
                                                  val?.no_of_days;
                                                let period = val?.time_period;
                                                let final_date = moment().add(
                                                  membership_number_of_days,
                                                  "days"
                                                );

                                                // if (final_date) {
                                                //   formik.setFieldValue(
                                                //     `students.${i}.membership_year`,
                                                //     final_date?.format("YYYY")
                                                //   );
                                                //   formik.setFieldValue(
                                                //     `students.${i}.membership_month`,
                                                //     final_date?.format("M")
                                                //   );
                                                //   formik.setFieldValue(
                                                //     `students.${i}.membership_day`,
                                                //     Number(
                                                //       final_date?.format("DD")
                                                //     )
                                                //   );
                                                // }
                                              }}
                                            />

                                            <img
                                              src="assets/images/address.png"
                                              className="input-img"
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  {id &&
                                  item?.trialAccountStatus === "3" &&
                                  item?.membership_level &&
                                  student_type ? (
                                    <>
                                      <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                                        <div className="form-group">
                                          {item?.membership_year &&
                                          item?.membership_day &&
                                          item?.membership_month &&
                                          student_type ? (
                                            <p>
                                              Your Membership Expires on:{" "}
                                              <span className="fw-bold">
                                                {" "}
                                                {membershipExpiry &&
                                                  membershipExpiry}
                                              </span>
                                            </p>
                                          ) : null}
                                        </div>
                                      </div>
                                      <>
                                        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                          <div className="form-group">
                                            <label className="fw-bold">
                                              Year
                                            </label>

                                            {formik.errors?.students?.[i]
                                              ?.membership_year &&
                                              formik.touched?.students?.[i]
                                                ?.membership_year && (
                                                <span className="text-red ms-1">
                                                  {
                                                    formik.errors?.students?.[i]
                                                      ?.membership_year
                                                  }
                                                </span>
                                              )}
                                            <div className="input-container">
                                              <select
                                                onChange={(e) => {
                                                  formik.setFieldValue(
                                                    `students.${i}.membership_year`,
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={formik.handleBlur}
                                                name={`students.${i}.membership_year`}
                                                value={
                                                  item?.membership_year || ""
                                                }
                                                className="form-control ps-4"
                                              >
                                                <option value="">
                                                  Select Year
                                                </option>

                                                {membershipYearList?.map(
                                                  (item) => {
                                                    return (
                                                      <option
                                                        value={item}
                                                        key={item}
                                                      >
                                                        {item}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>

                                              <img
                                                src="assets/images/choose-date.png"
                                                className="field-icon"
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                          <div className="form-group">
                                            <label className="fw-bold">
                                              Month
                                            </label>

                                            {formik.errors?.students?.[i]
                                              ?.membership_month &&
                                              formik.touched?.students?.[i]
                                                ?.membership_month && (
                                                <span className="text-red ms-1">
                                                  {
                                                    formik.errors?.students?.[i]
                                                      ?.membership_month
                                                  }
                                                </span>
                                              )}
                                            <div className="input-container">
                                              <select
                                                onChange={(e) => {
                                                  formik.setFieldValue(
                                                    `students.${i}.membership_month`,
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={formik.handleBlur}
                                                name={`students.${i}.membership_month`}
                                                value={
                                                  item?.membership_month || ""
                                                }
                                                className="form-control ps-4"
                                              >
                                                <option value="">
                                                  Select Month
                                                </option>
                                                {monthOption?.map((item) => (
                                                  <option
                                                    value={item?.id}
                                                    key={item?.id}
                                                  >
                                                    {item?.value}
                                                  </option>
                                                ))}
                                              </select>

                                              <img
                                                src="assets/images/choose-date.png"
                                                className="field-icon"
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                                          <div className="form-group">
                                            <label className="fw-bold">
                                              Day
                                            </label>

                                            {formik.errors?.students?.[i]
                                              ?.membership_day &&
                                              formik.touched?.students?.[i]
                                                ?.membership_day && (
                                                <span className="text-red ms-1">
                                                  {
                                                    formik.errors?.students?.[i]
                                                      ?.membership_day
                                                  }
                                                </span>
                                              )}
                                            <div className="input-container">
                                              <select
                                                onChange={(e) => {
                                                  formik.setFieldValue(
                                                    `students.${i}.membership_day`,
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={formik.handleBlur}
                                                name={`students.${i}.membership_day`}
                                                value={
                                                  item.membership_day || ""
                                                }
                                                className="form-control ps-4"
                                              >
                                                <option value={""}>
                                                  Select Day
                                                </option>
                                                {[
                                                  ...Array(
                                                    item.membership_day === "2"
                                                      ? 28
                                                      : 31
                                                  ).keys(),
                                                ].map((item) => {
                                                  return (
                                                    <option
                                                      value={item + 1}
                                                      key={item + 1}
                                                    >
                                                      {item + 1}
                                                    </option>
                                                  );
                                                })}
                                              </select>

                                              <img
                                                src="assets/images/choose-date.png"
                                                className="field-icon"
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    </>
                                  ) : item?.trialAccountStatus === "3" &&
                                    item?.membership_level &&
                                    !student_type ? (
                                    <span className="text-danger mb-4">
                                      Kindly add student's date of birth to view
                                      the membership.
                                    </span>
                                  ) : null}
                                  <div className="col-sm-6 col-md-6 mb-4">
                                    <div className="form-group">
                                      <label className="fw-bold">
                                        Area Name
                                      </label>
                                      {formik.errors.students?.[i]?.areacode &&
                                        formik.touched.students?.[i]
                                          ?.areacode && (
                                          <span className="text-red ms-1">
                                            {
                                              formik.errors.students?.[i]
                                                ?.areacode
                                            }
                                          </span>
                                        )}
                                      <div className="input-container">
                                        <select
                                          className="form-control pl-5 form-select"
                                          name={`students.${i}.areacode`}
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              `students.${i}.areacode`,
                                              e.target.value
                                            );
                                            setAreacode(e.target.value);
                                            getList(e.target.value, i);
                                          }}
                                          value={item?.areacode}
                                        >
                                          <option value="school">School</option>
                                          {areaList?.map((item) => (
                                            <option
                                              value={item?.id}
                                              key={item?.id}
                                            >
                                              {item?.areacode}
                                            </option>
                                          ))}
                                        </select>
                                        <img
                                          src="assets/images/address.png"
                                          className="input-img"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>{" "}
                                  {item.areacode && (
                                    <div className="col-sm-6 col-md-6 mb-4">
                                      <div className="form-group">
                                        <label className="fw-bold">
                                          School Venue
                                        </label>
                                        {formik.errors.students?.[i]
                                          ?.schoolId &&
                                          formik.touched.students?.[i]
                                            ?.schoolId && (
                                            <span className="text-red ms-1">
                                              {
                                                formik.errors.students?.[i]
                                                  ?.schoolId
                                              }
                                            </span>
                                          )}
                                        <div className="input-container">
                                          <select
                                            className="form-control pl-5 form-select"
                                            name={`students.${i}.schoolId`}
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                `students.${i}.schoolId`,
                                                e.target.value
                                              );
                                            }}
                                            value={item?.schoolId}
                                          >
                                            <option value="school">
                                              School
                                            </option>
                                            {item?.venueList?.map((item, i) => (
                                              <option
                                                value={item?.id}
                                                key={item?.id}
                                              >
                                                {item?.name}
                                              </option>
                                            ))}
                                          </select>
                                          <img
                                            src="assets/images/address.png"
                                            className="input-img"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div className="col-sm-6 col-md-6 mb-4">
                                    <div className="form-group">
                                      <label className="fw-bold">
                                        Emergency Contact Number
                                      </label>
                                      {formik.errors.students?.[i]
                                        ?.emergency_mobile && (
                                        <span className="text-red ms-1">
                                          {
                                            formik.errors.students?.[i]
                                              ?.emergency_mobile
                                          }
                                        </span>
                                      )}
                                      <div className="input-container">
                                        <Phone
                                          onChange={(val) => {
                                            if (val) {
                                              formik.setFieldValue(
                                                `students.${i}.emergency_mobile`,
                                                val
                                              );
                                            } else {
                                              formik.setFieldValue(
                                                `students.${i}.emergency_mobile`,
                                                ""
                                              );
                                            }
                                          }}
                                          value={item.emergency_mobile}
                                          name={`students.${i}.emergency_mobile`}
                                          className="form-control signupClass"
                                          isValid={() => {
                                            isStudentNumberValid(
                                              item?.emergency_mobile,
                                              i
                                            );
                                          }}
                                        />
                                        {/* <img
                                        src="assets/images/mobile.png"
                                        className="input-img"
                                        alt=""
                                      /> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-12 mb-4">
                                    <div className="form-group">
                                      <label className="fw-bold">
                                        {/* <img
                                        src="assets/images/medical.png"
                                        alt=""
                                      />{" "} */}
                                        Medical Note
                                      </label>
                                      {formik.errors.students?.[i]
                                        ?.medical_note &&
                                        formik.touched.students?.[i]
                                          ?.medical_note && (
                                          <span className="text-red ms-1">
                                            {
                                              formik.errors.students?.[i]
                                                ?.medical_note
                                            }
                                          </span>
                                        )}
                                      <div className="input-container">
                                        <textarea
                                          className="textarea-control p-3"
                                          rows="7"
                                          placeholder="Write here..."
                                          name={`students.${i}.medical_note`}
                                          value={item.medical_note}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  {formik.values.students?.length > 1 && (
                                    <div className="col-sm-12">
                                      <hr />
                                    </div>
                                  )}
                                  <div
                                    className="crossButton"
                                    onClick={() => {
                                      if (item?.studentId && id) {
                                        setDelId(item?.studentId);
                                        setDelIndex(i);
                                        handleShow();
                                        // arrayHelpers.remove(i);
                                        // handleDeleteData(item?.studentId, i)
                                      } else {
                                        arrayHelpers.remove(i);
                                      }
                                    }}
                                  >
                                    X
                                  </div>
                                </div>
                              );
                            })}

                            <div className="col-sm-12 mb-4">
                              <button
                                className="btn btn-save w-100"
                                type="button"
                                onClick={() => {
                                  arrayHelpers.push({
                                    first_name: "",
                                    last_name: "",
                                    dob: "",
                                    student_type: "",
                                    discipline: "",
                                    emergency_contactNo: "",
                                    emergency_countryCode: "+44",
                                    medical_note: "",
                                    schoolId: "",
                                    areacode: "",
                                    venueList: [],
                                    trialAccountStatus: false,
                                    gender: "",
                                  });
                                }}
                              >
                                + Add Additional Student
                              </button>
                            </div>
                          </>
                        );
                      }}
                    />

                    <div className="col-sm-12 col-md-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-save"
                        disabled={submitting}
                      >
                        {submitting ? <Loader /> : "Done"}
                      </button>
                    </div>
                  </div>
                </form>
              </FormikProvider>
            </div>
          </div>
          {show && (
            <DeleteModal
              page="student"
              submitting={delSubmitting}
              show={show}
              handleShow={handleShow}
              confirmDelete={confirmDelete}
              cancel={() => {
                setShow(false);
              }}
            />
          )}
        </div>
      </article>
    );
  }
}

export default Adduser;
